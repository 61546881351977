const $header = $('.header .container');
const $dropdownButton = $('#dropdown-destinos');
const $listaDeDestinos = $('.header .lista-de-destinos');
const $body = $(document.body);
const $menuMobileToggle = $('.header .menu-mobile-toggle');


var lockedDropDown = false;
const ANIMATION_DROPDOWN_TIME = 200;

/**
 * Menu mobile toggle
 */
{
    const $menuMobileNav = $('#menu-mobile-nav');
    
    let mustRecalculatePosition = true;
    
    let recalculate = function recalculate() {
        const offfsetLeft = $header.offset().left;
        
        $menuMobileNav.css({
            'left':  -1 * offfsetLeft + 'px',
            'padding-left' : offfsetLeft,
            'padding-right' : offfsetLeft
        });
        /**
         * Modo mobile
         */
        if ($menuMobileToggle.css('display') === 'block') {
            $listaDeDestinos.css({
                'padding-left' : offfsetLeft,
                'padding-right' : offfsetLeft
            });
        }

        mustRecalculatePosition = false;
    };

    window.toggleMenuMobile = function toggleMenuMobile() {
        if (mustRecalculatePosition === true) {
            recalculate();
        }

        if ($dropdownButton.hasClass('expanded')) {
            $dropdownButton.removeClass('expanded');
            $listaDeDestinos.slideUp(ANIMATION_DROPDOWN_TIME, function() {
                $listaDeDestinos.removeClass('expanded');
                lockedDropDown = false;
            });
        }

        $body.toggleClass('scroll-locked');
        $menuMobileToggle.toggleClass('active');
        $menuMobileNav.toggleClass('expanded');
        $menuMobileNav.slideToggle(200);
    };
    window.addEventListener('resize', function(_) {
        mustRecalculatePosition = true;

        if ($menuMobileNav.hasClass('expanded')) {
            recalculate();
        }
    })
}


/**
 * Dropdown de destinos hover desktop
 */
{
    /*Faz o bind do evento no mouse over dos botões */
    $dropdownButton.hover(function(_) {
        if (lockedDropDown === false) {
            $dropdownButton.addClass('expanded');
            $listaDeDestinos.addClass('expanded');
            $listaDeDestinos.slideDown(ANIMATION_DROPDOWN_TIME);
        }
    });
    $header.on('mouseout', function(event) {
        if ($header.is(':hover')) return;
        if (!lockedDropDown && !$listaDeDestinos.is(':hover')) {
            lockedDropDown = true;
            $dropdownButton.removeClass('expanded');
            $listaDeDestinos.removeClass('expanded');
            $listaDeDestinos.slideUp(ANIMATION_DROPDOWN_TIME, function() {
                lockedDropDown = false;
                if ($dropdownButton.is(':hover')) {
                    /* Se o mouse permacer sobre o botão, dispara novamente o evento */
                    $dropdownButton.trigger('mouseenter');
                }
            });
        }
    });
    /* Faz o bind do evento ao dar mouse out no submenu */
    $listaDeDestinos.hover(() => {/* Faz nada*/}, function() {
        if ($header.is(':hover')) return;
        if (!$dropdownButton.is(':hover')) {
            lockedDropDown = true;
            $dropdownButton.removeClass('expanded');
            $listaDeDestinos.slideUp(ANIMATION_DROPDOWN_TIME, function() {
                $listaDeDestinos.removeClass('expanded');
                lockedDropDown = false;
            });
        }
    });
}